import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Header, HeaderInner, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns } from 'src/interface/basics/headers';
import { BtnLink, BtnNextLink, NextLink } from 'src/interface/basics/links';
import ShapesAnimation from 'src/interface/animations/shapes-animation';
import { GDesk } from 'src/assets';

const ProcessHeader = () => {

  return (
    <Header className='py-5 gradient-primary-accent'>
      <HeaderInner className='mvh-40'>
        <Container className='z-20' fluid='md'>
          <Row>
            <Col lg={8}>
              <HeaderBody className='animate fade-in-up delay-200'>
                <HeaderPretitle>Our Playbooks</HeaderPretitle>
                <HeaderTitle>Your swiss army knife</HeaderTitle>
                <HeaderText>
                  We use our flavor of agile that breaks projects down into smaller, more nimble sprints.
                  That way, you'll have plenty of opportunity to collaborate and share feedback while we push forward.
                </HeaderText>
                <HeaderBtns className='animate fade-in-up delay-400'>
                  <BtnNextLink to='/contact' className='btn btn-opaque-white btn-lg'>Contact Us</BtnNextLink>
                </HeaderBtns>
              </HeaderBody>
            </Col>
          </Row>
        </Container>
      </HeaderInner>
      <ShapesAnimation />
    </Header>
  )
}

export default ProcessHeader;
