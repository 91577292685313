import React, { useState } from 'react';
import { GCollab, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ProcessSectionDeploy = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='deploy' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>Deploy</SectionPretitle>
                <SectionTitle>The finishing touches</SectionTitle>
                <SectionText>
                  Before going to production, we always finish with a system-wide audit to ensure smooth-sailing well into the future.
                  Typically that means documentation, account transfers, system monitoring, bug tracking, database backups, security audits, and more.
                  That said, things do happen from time to time and we'll stand by our work knowing the customer comes first.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/contact' className='btn-text-primary'>Get Started</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <LazyImg src={GCollab} alt='Collaboration photo' className='relative z-1'/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ProcessSectionDeploy;
