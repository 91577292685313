import React, { useState, createRef } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import PlaybookHeader from 'src/components/playbooks/playbook-header';
import PlaybookNav from 'src/components/playbooks/playbook-nav';
import PlaybookSectionDiscovery from 'src/components/playbooks/playbook-section-discovery';
import PlaybookSectionDesign from 'src/components/playbooks/playbook-section-design';
import PlaybookSectionDevelopment from 'src/components/playbooks/playbook-section-development';
import PlaybookSectionReview from 'src/components/playbooks/playbook-section-review';
import PlaybookSectionDeploy from 'src/components/playbooks/playbook-section-deploy';
import PlaybookSectionFeedback from 'src/components/playbooks/playbook-section-feedback';
import PlaybookSectionBatteries from 'src/components/playbooks/playbook-section-batteries';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const PlaybooksPage = () => {
  const [ subnav, setSubnav ] = useState(false);


  // Render
  return (
    <MktRoute title='Mile Hi Labs | Our Playbooks' description="Whether we've spent weeks or months on your project, we want it to be shared and used by the world just as much as you do.">
      <MktNav />
      <PlaybookHeader />
      <Waypoint bottomOffset='99.9%' onEnter={() => !subnav && setSubnav(true)} onLeave={() => setSubnav(false)}>
        <main className='relative mvh-50'>
          <PlaybookNav active={subnav} />
          <PlaybookSectionDiscovery className='' />
          <PlaybookSectionDesign className='' />
          <PlaybookSectionDevelopment className='' />
          <PlaybookSectionReview className='' />
          <PlaybookSectionDeploy className='' />
          <PlaybookSectionFeedback className='' />
          <PlaybookSectionBatteries className='' />
          <MktPrefooter />
        </main>
      </Waypoint>
      <MktFooter />
    </MktRoute>
  )
}

export default PlaybooksPage;
