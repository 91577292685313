import React, { useState } from 'react';
import { Development, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ProcessSectionDevelopment = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='development' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>Development</SectionPretitle>
                <SectionTitle>Rubber hits the road</SectionTitle>
                <SectionText>
                  The bulk of our work will take place in development where we connect the dots adding functionality and data integration(s) to the project we've already built.
                  At this point, you can expect regular demos from our team giving you the opportunity to test and share feedback while we push forward.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/contact' className='btn-text-primary'>Get Started</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <LazyImg src={Development} alt='Browser coding photo' className='relative z-1' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ProcessSectionDevelopment;
