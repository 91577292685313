import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import FeatureCard from 'src/interface/cards/feature-card';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText } from 'src/interface/basics/sections';
import { GCollab } from 'src/assets';

const ProcessSectionBatteries = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section id='batteries' className={`py-8 animate ${animation} ${className}`}>
        <SectionHeader>
          <Container className='md text-center'>
            <SectionPretitle>expectations</SectionPretitle>
            <SectionTitle>A process you can count on</SectionTitle>
            <SectionText>
              Whether we've spent weeks or months on your project, we want it to be shared and used by the world just as much as you do.
              That's why every project we ship needs to match your standards as well as ours.
            </SectionText>
          </Container>
        </SectionHeader>

        <SectionBody className='pt-5'>
          <Container className='lg'>
            <Row>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='calendar-check'
                  title='Weekly Standups'
                  description="Weekly standups keep the project on schedule and allow time for collaboration."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='laptop-code'
                  title='Code Review'
                  description="Internal testing and peer-review ensure code clarity, scalability, and usability for the end-user."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='play'
                  title='Regular Demos'
                  description="Regular demos give your team ample opportunity to review and share the project with others."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='code-merge'
                  title='Automatic Hooks'
                  description="Commit / deploy hooks will alert your team when code gets pushed and new builds are available."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='comment-check'
                  title='System Audit'
                  description="System audits ensure your project meets all our quality standards as if it were our own."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='graduation-cap'
                  title='Team Training'
                  description="Team training ensures your team's comfort and confidence in every aspect of the project."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='receipt'
                  title='Detailed Billing'
                  description="Detailed billing maintains transparency and helps everyone gauge future projects."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='books'
                  title='Documentation'
                  description="We document every project so anyone can access, navigate, and contribute to the project in the future."
                />
              </Col>
              <Col sm={6} md={4}>
                <FeatureCard
                  icon='key'
                  title='Account Ownership'
                  description="From the very start, you'll own every aspect of your project so there's never an issue with hand-off."
                />
              </Col>
            </Row>
          </Container>
        </SectionBody>
      </section>
    </Waypoint>
  )
}

export default ProcessSectionBatteries;
