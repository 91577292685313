import React, { useState } from 'react';
import { Discovery, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';

const ProcessSectionDiscovery = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='discovery' className={`pt-14 pb-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <SectionPretitle>Discovery</SectionPretitle>
                <SectionTitle>Start with the basics</SectionTitle>
                <SectionText>
                  In this phase, we'll deep dive into all aspects of your project so we can establish a road map, budget forecast and our shared objectives.
                  This phase typically consists of a few meetings, our proposal, and eventually a signed contract and deposit before moving into the next phase.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/contact' className='btn-text-primary'>Get Started</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <LazyImg src={Discovery} alt='Browser task management photo' className='relative z-1' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ProcessSectionDiscovery;
