import React, { useState } from 'react';
import { Design, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ProcessSectionDesign = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='design' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>Design</SectionPretitle>
                <SectionTitle>Design is how it works</SectionTitle>
                <SectionText>
                  In this phase, we'll visualize your concept via flowcharts and wireframes taking into account the underlying data and coding we'll use down the road.
                  Once everyone's on the same page, we'll design in code so you get a dynamic prototype that we can modify and fill in from there.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/contact' className='btn-text-primary'>Get Started</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <LazyImg src={Design} alt='Design software photo' className='relative z-1'/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ProcessSectionDesign;
