import React, { useState } from 'react';
import { Review, Blob } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const ProcessSectionReview = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='review' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 1}}>
              <div className='mb-5'>
                <SectionPretitle>Review</SectionPretitle>
                <SectionTitle>Performance and usability</SectionTitle>
                <SectionText>
                  Though we constantly test and review our work in development this phase is reserved for a deeper inspection.
                  We typically start this phase by optimizing both the code and user experience to ensure it's future-proof and production-ready.
                  Then, we'll conduct multiple audits to ensure everything is functioning as desired before your final review.
                </SectionText>
                <SectionBtns>
                  <NextLink to='/contact' className='btn-text-primary'>Get Started</NextLink>
                </SectionBtns>
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className=''>
                <Blob className='top-0 absolute z-0 fill-secondary o-300' />
                <LazyImg src={Review} alt='Developer collaboration photo' className='relative z-1'/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default ProcessSectionReview;
